@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,400;1,300&family=Space+Mono&display=swap');

font-1 {
  font-family: 'Chakra Petch';
}

body {
  background-color: #000212;
}

.card {
  /* Add position relative for absolute positioning */
  position: relative;
  width: 500px;
  height: 250px;
  background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%);
  border-radius: 20px;
  transition: all 0.3s;
  overflow: hidden;
  /* Hide overflow to contain the background image */
}

@media (max-width: 600px) {
  .card {
    width: 320px;
    height: 220px;
  }
}

.card:hover {
  box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.30);
}

.card-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  /* Start with opacity 0 and transition to 1 on hover */
  transition: opacity 0.3s;
}

.card:hover .card-description {
  opacity: 1;
}

.card-description p {
  color: white;
  font-size: 18px;
  text-align: center;
}

.card2 {
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  border-radius: 10px;
  transition: all 0.2s;
}

.card:hover .card2 {
  transform: scale(0.98);
  border-radius: 20px;
}

.fade-in-card {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.fade-in-card.show {
  opacity: 1;
}

video {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: transparent;
  object-fit: cover;
}



.card3 {
  width: 400px;
  height: 280px;
  position: relative;
  display: flex;
  place-content: center;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
  background-color: #07182E;
}


.card3::before {
  content: '';
  position: absolute;
  width: 100px;
  background-image: linear-gradient(180deg, rgb(255, 0, 0), rgb(255, 0, 0));
  height: 200%;
  animation: rotBGimg 3s linear infinite;
  transition: all 0.2s linear;
}

@keyframes rotBGimg {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card3::after {
  content: '';
  position: absolute;
  background: #07182E;
  ;
  inset: 5px;
  border-radius: 15px;
}

.button1 {
  padding: 1.3em 3em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 700;
  color: white;
  background-color: #822E81;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.button1:hover {
  background-color: #D36135;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

.button1:active {
  transform: translateY(-1px);
}